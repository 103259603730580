*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
*::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
    background-color: rgba(17, 23, 26, 0.8);
    border-radius: 10px;
}

* {
    outline: none;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    font-style: normal;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: inherit;
}

strong {
    font-weight: bold;
}


p,h1,h2,h3 {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}


@media screen and (max-width:1200px) {

}