.loader {
    position: relative;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 15px;
    background-color: rgba(227, 227, 227, 0.38);
    overflow: hidden;
    border-radius: 20px;
}

.loader:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
    animation: gradient-animation_2 1.2s linear infinite;
}

.loader .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 10px;
}

.loader .wrapper > div {
    background-color: rgba(202, 202, 202, 0.55);
}

.loader .circle {
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.loader .button {
    display: inline-block;
    height: 32px;
    width: 75px;
}

.loader .line1 {
    height: 150px;
    width: 100%;
}

.loader .line2 {
    height: 10px;
    width: 150px;
}

.loader .line3 {
    height: 10px;
    width: 100%;
}

.loader .line4 {
    height: 10px;
    width: 92%;
}

@keyframes gradient-animation_2 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}