/* Style for the table container */
.tableContainer {
    width: 100%;
    /*overflow-x: auto;  !* Enables horizontal scrolling if table is too wide *!*/
    font-size: 0.8rem;
}

/* Style for the table */
table {
    width: 100%;
    border-collapse: collapse;  /* Eliminates the space between table cells */
}

/* Style for the table headers */
thead tr {
    background-color: #F26725;  /* Orange background for header row */
    color: white;  /* White text color */
    font-weight: bold;  /* Bolder font weight */
    height: 50px !important;
    text-align: center !important;
}
thead tr th {
    text-align: center !important;
}

/* Style for table data cells */
td, th {
    padding: 8px;  /* Padding inside each cell */
    text-align: left;  /* Aligns text to the left */
    border-bottom: 1px solid #ddd;  /* Light grey border below each row */
    height: 100px;
}

/* Alternating row colors */
tbody tr:nth-child(odd) {
    background-color: #F1F0F0;  /* Light grey for odd rows */
    cursor: pointer;
    transition: 0.3s;
}

tbody tr:nth-child(odd):hover {
    background-color: #d0d0d0;  /* Light grey for odd rows */
}

tbody tr:nth-child(even) {
    background-color: #E7E6E6;  /* A slightly darker shade of grey for even rows */
    cursor: pointer;
    transition: 0.3s;
}

tbody tr:nth-child(even):hover {
    background-color: #d0d0d0;  /* Light grey for odd rows */
}

/* Font color for table data */
tbody tr td {
    color: black;  /* Black text color for data cells */
}
